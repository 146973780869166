import {Header, Content, Panel} from 'rsuite';
import {Logo} from "../Components/Main/Logo"
import {Portfolio} from "../Components/Main/Portfolio"
import {AboutMe} from "../Components/Main/AboutMe";

const Main = () => {
    return(
    <div lang={"en"}>
        <Header>
            <Panel>
                <Logo></Logo>
            </Panel>
        </Header>
        <Content>
            <Portfolio></Portfolio>
            <AboutMe></AboutMe>
        </Content>
    </div>
    )
}


export default Main;