import {Panel, FlexboxGrid, Col} from "rsuite";

export function AboutMe () {
    return(
        <Col lgPush={6} lg={12}>
            <FlexboxGrid justify={"center"}>
                <Panel className={"aboutMe"} bordered={true} shaded={true}>
                    <h1 className={"horizontalCenterHeader"}>About Me</h1>
                    <p>
                        &emsp; With a background in elementary education, I have developed exceptional problem-solving, communication, and collaboration skills. Over the past year, I have
                        dedicated myself to self-studying Java, C#, JavaScript and React while practicing SOLID and UI design principles to create a web application.
                        In my free time, I enjoy gardening, playing with my dogs, and crafting. My background in education and my diverse interests have been indispensable while learning to program.
                    </p>
                </Panel>
            </FlexboxGrid>
        </Col>

    );
}