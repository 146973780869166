import {Panel,FlexboxGrid, Placeholder} from "rsuite";
// @ts-ignore
import CardMatchZip from "../../Downloads/CardMatchZip.zip"


export function Portfolio() {
    return(

            <div>
                <FlexboxGrid justify={"center"}>
                    <FlexboxGrid.Item className={"cardSpacing"}>
                        <a href={CardMatchZip} download={"CardMatch"}>
                        <Panel className={"cardStyling"} shaded={true} bordered={true} bodyFill={true} style={{ display: 'inline-block', width: 240 }}>
                            <Placeholder graph={"image"}></Placeholder>
                            <Panel header="Card Match Game">
                                <p>
                                    <small>
                                        Flip and match the cards to win the game. <br/>Click To Download.
                                    </small>
                                </p>
                            </Panel>
                        </Panel>
                        </a>
                </FlexboxGrid.Item>
                    <FlexboxGrid.Item className={"cardSpacing"}>
                        <a href={"https://purple-pebble-066565910.3.azurestaticapps.net/"}>
                            <Panel className={"cardStyling"} shaded={true} bordered={true} bodyFill={true} style={{ display: 'inline-block', width: 240 }}>
                                <Placeholder graph={"image"}></Placeholder>
                                <Panel header="Household Inventory System">
                                    <p>
                                        <small>
                                            A program for keeping inventory of the contents of your home.
                                        </small>
                                    </p>
                                </Panel>
                            </Panel>
                        </a>
                    </FlexboxGrid.Item>
                <FlexboxGrid.Item className={"cardSpacing"}>
                    <a href={"https://ashy-bush-072793610.4.azurestaticapps.net/"}>
                        <Panel className={"cardStyling"} shaded={true} bordered={true} bodyFill={true} style={{ display: 'inline-block', width: 240 }}>
                            <Placeholder graph={"image"}></Placeholder>
                            <Panel header="Card Matching Web App">
                                <p>
                                    <small>
                                        Flip and match the cards to win the game. <br/>Written as a Blazor app.
                                    </small>
                                </p>
                            </Panel>
                        </Panel>
                    </a>
                </FlexboxGrid.Item>
                </FlexboxGrid>
        </div>
    )
}